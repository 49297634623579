 .nav {
    background: #F8C9DD;
    font-family: 'Gabriela';
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    text-decoration: none;
    
  }
  
  .Brand {
    font-weight: bold;
    font-style: italic;
    margin-left: 2rem;
    padding-right: 2rem;
    text-decoration: none;
    font-size: 20px;
  }
  
  ul {
    display: flex;
    text-decoration: none;
    margin-right: 2rem;
    text-align: center;
    
  }
  
li {
    flex: 0 0 auto;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    align-items: center;
    height: 100%;
    justify-content: center;
    text-decoration: none;
    display: flex;
    font-size: 17px;
    height: 50px;
    line-height: 16px;
    margin: 0 10px ;
    text-decoration: none;
    white-space: nowrap; 
}

a:link { text-decoration: none; }
a:visited { text-decoration: none; }